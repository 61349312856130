<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button
          variant="primary"
          @click="$refs.refInputEl.click()"
        >
          <input
            ref="refInputEl"
            type="file"
            class="d-none"
            @input="inputImageRenderer"
          >
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon
            icon="EditIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
        <b-button
          variant="outline-secondary"
          class="ml-1"
        >
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon
            icon="TrashIcon"
            class="d-inline d-sm-none"
          />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="userData.username"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Nome -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nome"
            label-for="full-name"
          >
            <b-form-input
              id="full-name"
              v-model="userData.fullName"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="userData.email"
              type="email"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Status"
            label-for="user-status"
          >
            <v-select
              v-model="userData.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-status"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="User Role"
            label-for="user-role"
          >
            <v-select
              v-model="userData.roles.name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="user-role"
              @input="onRoleChange"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Dealer -->

        <b-col
          v-if="userData.roles.name.toLowerCase().includes('dealer')"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Dealer"
            label-for="company-person"
          >

            <v-select
              v-model="userData.DealerID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              :options="agencies"
              label="company"
              :disabled="loggedUser.role != 'admin' && !loggedUser.role.toLowerCase().includes('acrisure')"
              :reduce="val => val.Id"
            />

            <!--
               //index="Id"
              //:reduce="val => val.Id"
              input-id="company-person"
            -->
          </b-form-group>
        </b-col>
        <!-- Field: Company -->
        <b-col
          v-if="userData.roles.name.toLowerCase().includes('compagnia')"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Compagnia"
            label-for="company-person"
          >
            <v-select
              v-model="userData.CompanyID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              :options="companies"
              label="company"
              :disabled="loggedUser.role != 'admin'"
              index="Id"
              :reduce="val => val.Id"
              input-id="company-person"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Sales Person -->
        <b-col
          v-if="userData.roles.name == 'agent'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Employee"
            label-for="employee"
          >
            <v-select
              v-model="userData.empID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="true"
              :options="employees.some(x => x.eMail == (userData.email || x.eMail))?employees.filter(x => x.eMail == (userData.email || x.eMail)):employees"
              label="fullName"
              index="empID"
              :reduce=" x => x.empID"
              input-id="employee"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company"
            label-for="company"
          >
            <b-form-input
              id="company"
              v-model="userData.company"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: basePath -->


        <!--
        <b-col
          v-if="loggedUser.role == 'admin'"
          cols="12"
          md="4"
        >
          <b-form-group
            label="Base Path"
            label-for="basepath"
          >
            <b-form-input
              id="basepath"
              v-model="userData.BasePath"
            />
          </b-form-group>
        </b-col>
      -->
      </b-row>
    </b-form>
    <!-- PERMISSION TABLE -->
    <b-card
      v-if="$can('write', 'Permessi')"
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="currentRole.abilities"
      >
        <template #head(subject)>
          <div class="text-nowrap" />
        </template>
        <template #cell(subject)="" />
        <template #cell(name)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            disabled
            :v-bind="data"
            :checked="data.value"
            @change="statusChanged(data)"
          />
        </template>
      </b-table>
    </b-card>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="saveChanges()"
    >
      Salva
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BIconNutFill,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import useUsersList from '../users-list/useUsersList'
import userStoreModule from '../userStoreModule'
import agencyStoreModule from '../../dealer/Agency/agencyStoreModule'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    roles: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    currentRole: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    roleOptions: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    agencies: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    companies: {
      required: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    employees: {
      required: true,
    },
  },
  mounted() {
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const AGENCY_STORE_MODULE_NAME = 'app-agency'
    const listaDealer = ref(null)

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(AGENCY_STORE_MODULE_NAME)) store.registerModule(AGENCY_STORE_MODULE_NAME, agencyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(AGENCY_STORE_MODULE_NAME)
      if (store.hasModule(AGENCY_STORE_MODULE_NAME)) store.unregisterModule(AGENCY_STORE_MODULE_NAME)
    })
    const { resolveUserRoleVariant } = useUsersList()
    /*
    const fetchAllAgency = () => {
      store.dispatch(`${AGENCY_STORE_MODULE_NAME}/fetchAllDealer`)
        .then(res => {
          console.log("dealer", res.data.agency)
          listaDealer.value = res.data.agency
          // companies.value = res.data.companies
          // const searchAndBookmarkDataPages = ref({ key: 'company', data: companies.value })
        })
        .catch(error => {
          if (error.response.status === 404) {
            listaDealer.value = undefined
          }
        })
    }
*/

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]
    const loggedUser = getUserData()
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })


    return {
      resolveUserRoleVariant,
      avatarText,
      statusOptions,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
      loggedUser,
      listaDealer,
      // fetchAllAgency,
      // companies,
    }
  },
  methods: {
    onRoleChange(role) {
      this.$emit('updateCurrentRole', this.roles.roles.filter(x => x.name == role)[0])
    },
    statusChanged(data) {
      this.currentRole.abilities.filter(x => x.subject == data.item.subject)[0][data.field.key] = !this.currentRole.abilities.filter(x => x.subject == data.item.subject)[0][data.field.key]
      // this.$emit('onChangeCurrentRole', { index: data.index, value: data.rowSelected, we: this.currentRole })
    },
    saveChanges() {
      this.$emit('saveChanges', {})
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
