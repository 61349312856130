import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCompanies(ctx = null, queryParams = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/company/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllCompany(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/company/all`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/company/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AddUpdateCompany(ctx, { company, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/company/addupdate', { obj: company, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/countries')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
