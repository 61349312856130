import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSalesNetworkChildren(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/agency/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUpdateSalesNetworkItem(ctx, { smItem }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/sale-network/addUpdate', { salesManagerItem: smItem })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAllDealer(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/agency/agency-list')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgencies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsdlr/agency/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsersByAgencies(ctx, agencyId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/agency/${agencyId}/users`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgency(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/agency/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAgency(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsdlr/agency/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    AddUpdateAgency(ctx, { agency, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsdlr/agency/addupdate', { obj: agency, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/company/countries')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // addUser(ctx, userData) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post('/apps/user/users', { user: userData })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
  },
}
