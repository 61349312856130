<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'japps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-tabs
      v-if="userData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>

        <user-sales-network
          :user-id="userData.id"
        />

        <user-edit-tab-account
          :user-data="userData"
          :roles="roles"
          :current-role="currentRole"
          :role-options="roleOptions"
          :agencies="agencies"
          :companies="companies"
          :employees="employees"
          class="mt-2 pt-75"
          @updateCurrentRole="currentRole=$event;"
          @onChangeCurrentRole="onChangeCurrentRoleEvent($event);"
          @saveChanges="saveChanges($event);"
        />
      </b-tab>
      <!-- "currentRole[$event.index].value = $event.value;" -->
      <!-- Tab: Information
      <b-tab>
        <template #title>
          <feather-icon
            icon="InfoIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Information</span>
        </template>
        <user-edit-tab-information class="mt-2 pt-75" />
      </b-tab>
      -->
      <!-- Tab: Social -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Change Password</span>
        </template>
        <user-edit-change-password
          :user-data="userData"
          class="mt-2 pt-75"
        />
      </b-tab>

    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditChangePassword from './UserEditChangePassword.vue'
import UserSalesNetwork from './UserSalesNetwork.vue'
import agencyStoreModule from '../../dealer/Agency/agencyStoreModule'
import companyStoreModule from '../../dealer/Company/companyStoreModule'
// Notification

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditChangePassword,
    UserSalesNetwork,
  },
  setup() {
    const userData = ref(null)
    const roles = ref(null)
    const agencies = ref([])
    const companies = ref([])
    const currentRole = ref(null)
    const roleOptions = ref(null)
    const employees = ref([])
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const AGENCY_DEALER_STORE_MODULE_NAME = 'app-agency'
    const COMPANY_DEALER_STORE_MODULE_NAME = 'app-company'
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.registerModule(AGENCY_DEALER_STORE_MODULE_NAME, agencyStoreModule)
    if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, companyStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(AGENCY_DEALER_STORE_MODULE_NAME)
      if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })


    onMounted(() => {
      store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          userData.value = response.data
          store.dispatch('app-user/fetchRoles', { })
            .then(res => {
              roles.value = res.data
              currentRole.value = roles.value.roles.filter(x => x.name == userData.value.roles.name)[0]
              roleOptions.value = roles.value.roles.map(role => ({ label: role.name[0].toUpperCase() + role.name.slice(1), value: role.name }))
              console.log(currentRole.value.abilities)
              Array.from(currentRole.value.abilities).forEach(role => {
                console.log(role)
                const actions = Object.keys(role)
                const { subject } = role
                actions.splice(actions.findIndex(item => item === 'subject'), 1)
                // console.log(actions)
                // Array.from(actions).forEach(action => {
                //   console.log(action)
                //   role[action] = userData.value.abilities.filter(x => x.action == action && x.subject == subject).length > 0
                // })
              })
            })
            .catch(error => {
              if (error.response.status === 404) {
                roles.value = undefined
              }
            })
        }).catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })
      store.dispatch('app-user/fetchEmployees', { })
        .then(res => {
          console.log(res.data)
          employees.value = res.data.ohem
        })
        .catch(error => {
          if (error.response.status === 404) {
            employees.value = []
          }
        })
      store.dispatch(`${AGENCY_DEALER_STORE_MODULE_NAME}/fetchAllDealer`, { })
        .then(res => {
          console.log(res.data)
          agencies.value = res.data.agencies
        })
        .catch(error => {
          if (error.response.status === 404) {
            agencies.value = undefined
          }
        })

      store.dispatch(`${COMPANY_DEALER_STORE_MODULE_NAME}/fetchAllCompany`, { })
        .then(res => {
          companies.value = res.data.companies
        })
        .catch(error => {
          if (error.response.status === 404) {
            companies.value = undefined
          }
        })
    })

    return {
      userData,
      roleOptions,
      currentRole,
      roles,
      agencies,
      employees,
      companies,
    }
  },
  methods: {
    onChangeCurrentRole(event) {
      console.log("===TESTEVENT===")
      console.log(event.we.abilities[0].subject)
      console.log(event.we.abilities[0].read)
      console.log(this.currentRole.abilities[0].subject)
      console.log(this.currentRole.abilities[0].read)
    },
    saveChanges(event) {
      console.log('save emit')
      store.dispatch('app-user/updateUser', {
        userData: this.userData,
        permissions: this.currentRole,
      })
        .then(response => {
          this.$router.go(this.$router.currentRoute)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while saving the changes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style>

</style>
