import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/base/user/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/base/user/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/base/user/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData, permissions) {
      return new Promise((resolve, reject) => {
        axios
          .post('/base/user/users', userData, permissions)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, userData, password, oldpassword = null) {
      return new Promise((resolve, reject) => {
        axios
          .post('/base/user/changepwd', userData, password, oldpassword)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/base/roles/abilities', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRoleList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/base/roles/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUpdateRolePermission(ctx, userData, role) {
      console.log('===UPDATE===')
      return new Promise((resolve, reject) => {
        axios
          .post('/base/roles/abilities', userData, role)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRole(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/base/roles/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, userData, permissions) {
      console.log('===UPDATE===')
      return new Promise((resolve, reject) => {
        axios
          .post('/base/user/update', userData, permissions)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSalesPerson(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wscrm/oslp/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmployees(ctx, { queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/base/ohem/list`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
