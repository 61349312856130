<template>
  <b-card
    title="Dealer collegati"
  >
    <b-row>
      <b-col md="12">
        {{ userId }}
      </b-col>
      <b-col md="12">
        <b-form-group
          label="Dealer"
          label-for="newDealer"
        >
          <b-form-input
            id="newDealer"
            v-model="newDealer"
            placeholder="ID Dealer"
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-button
          variant="primary"
          class="mt-2 mb-1 mb-sm-0 mr-0 mr-sm-1"
          @click="addSalesNetworkChildren"
        >Cerca
        </b-button>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BCol,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import agencyStoreModule from '../../dealer/Agency/agencyStoreModule'
// import userStoreModule from '../userStoreModule'
// import UserEditTabAccount from './UserEditTabAccount.vue'
// import UserEditTabInformation from './UserEditTabInformation.vue'
// import UserEditChangePassword from './UserEditChangePassword.vue'
// import companyStoreModule from '../../dealer/Company/companyStoreModule'
// Notification
export default {
  components: {
    BTab,
    BTabs,
    BAlert,
    BLink,
    BCard,
    BRow,
    BCol,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default() { return 0 },
    },
  },
  mounted() {
    console.log("================ mounted2")
    this.fetchSalesNetworkChildren()
  },
  setup() {
    /*
    const userData = ref(null)
    const roles = ref(null)
    const agencies = ref([])
    const companies = ref([])
    const currentRole = ref(null)
    const roleOptions = ref(null)
    const employees = ref([])
    */
    const childrenDealers = ref([])
    const newDealer = ref(0)

    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const AGENCY_DEALER_STORE_MODULE_NAME = 'app-agency'
    // const COMPANY_DEALER_STORE_MODULE_NAME = 'app-company'

    // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    if (!store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.registerModule(AGENCY_DEALER_STORE_MODULE_NAME, agencyStoreModule)
    // if (!store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.registerModule(COMPANY_DEALER_STORE_MODULE_NAME, companyStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(AGENCY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(AGENCY_DEALER_STORE_MODULE_NAME)
      // if (store.hasModule(COMPANY_DEALER_STORE_MODULE_NAME)) store.unregisterModule(COMPANY_DEALER_STORE_MODULE_NAME)
    })

    const fetchSalesNetworkChildren = () => {
      const params = { userId: this.userId }
      store.dispatch(`${AGENCY_DEALER_STORE_MODULE_NAME}/fetchSalesNetworkChildren`, params).then(res => {
        childrenDealers.value = res.data.childrenDealers
      })
        .catch(error => {
          if (error.response.status === 404) {
            childrenDealers.value = undefined
          }
        })
    }

    onMounted(() => {
      console.log("================ mounted1")
      // fetchSalesNetworkChildren()
      /*
      store.dispatch(`${AGENCY_DEALER_STORE_MODULE_NAME}/fetchSalesNetworkChildren`, { userId: this.userId }).then(res => {
        childrenDealers.value = res.data.childrenDealers
      })
        .catch(error => {
          if (error.response.status === 404) {
            childrenDealers.value = undefined
          }
        })
      */
      /*
      store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => {
          userData.value = response.data
          store.dispatch('app-user/fetchRoles', { })
            .then(res => {
              roles.value = res.data
              currentRole.value = roles.value.roles.filter(x => x.name == userData.value.roles.name)[0]
              roleOptions.value = roles.value.roles.map(role => ({ label: role.name[0].toUpperCase() + role.name.slice(1), value: role.name }))
              console.log(currentRole.value.abilities)
              Array.from(currentRole.value.abilities).forEach(role => {
                console.log(role)
                const actions = Object.keys(role)
                const { subject } = role
                actions.splice(actions.findIndex(item => item === 'subject'), 1)
                // console.log(actions)
                // Array.from(actions).forEach(action => {
                //   console.log(action)
                //   role[action] = userData.value.abilities.filter(x => x.action == action && x.subject == subject).length > 0
                // })
              })
            })
            .catch(error => {
              if (error.response.status === 404) {
                roles.value = undefined
              }
            })
        }).catch(error => {
          if (error.response.status === 404) {
            userData.value = undefined
          }
        })

      store.dispatch('app-user/fetchEmployees', { })
        .then(res => {
          console.log(res.data)
          employees.value = res.data.ohem
        })
        .catch(error => {
          if (error.response.status === 404) {
            employees.value = []
          }
        })

      store.dispatch(`${AGENCY_DEALER_STORE_MODULE_NAME}/fetchAllDealer`, { })
        .then(res => {
          console.log(res.data)
          agencies.value = res.data.agencies
        })
        .catch(error => {
          if (error.response.status === 404) {
            agencies.value = undefined
          }
        })

      store.dispatch(`${COMPANY_DEALER_STORE_MODULE_NAME}/fetchAllCompany`, { })
        .then(res => {
          companies.value = res.data.companies
        })
        .catch(error => {
          if (error.response.status === 404) {
            companies.value = undefined
          }
        })
      */
    })

    return {
      fetchSalesNetworkChildren,
      childrenDealers,
      newDealer,
    }
  },
  methods: {
    fetchSalesNetworkChildren() {
      const params = { userId: this.userId }
      store.dispatch(`app-agency/fetchSalesNetworkChildren`, params).then(res => {
        this.childrenDealers.value = res.data.childrenDealers
      })
        .catch(error => {
          if (error.response.status === 404) {
            this.childrenDealers.value = undefined
          }
        })
    },
    addSalesNetworkChildren() {
      console.log('save emit')
      store.dispatch(`app-agency/addUpdateSalesNetworkItem`, {
        newDealerId: this.newDealerId,
      })
        .then(response => {
          // this.$router.go(this.$router.currentRoute)
          this.fetchSalesNetworkChildren()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while saving the changes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    /*
    onChangeCurrentRole(event) {
      console.log("===TESTEVENT===")
      console.log(event.we.abilities[0].subject)
      console.log(event.we.abilities[0].read)
      console.log(this.currentRole.abilities[0].subject)
      console.log(this.currentRole.abilities[0].read)
    },
    saveChanges(event) {
      console.log('save emit')
      store.dispatch('app-user/updateUser', {
        userData: this.userData,
        permissions: this.currentRole,
      })
        .then(response => {
          this.$router.go(this.$router.currentRoute)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred while saving the changes',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    */
  },
}
</script>

<style>

</style>
